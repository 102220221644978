::-webkit-scrollbar {
    width: 1px;
    height: 1px;
}

// ::-webkit-scrollbar-button {
//     background: #ccc;
// }
// ::-webkit-scrollbar-track-piece {
//     background: #888;
// }
// ::-webkit-scrollbar-thumb {
//     background: #eee;
// }​

.section-content {
    background: #fff;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content-item {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    flex-wrap: wrap-reverse;
    background: lightseagreen;
    overflow: hidden;

}

.content-item-body {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap-reverse;
    overflow: hidden;
    width: 100%;
    padding: 25px 0px;
    max-width: 900px;
    align-items: center;
}

.content-item img {
    width: 300px;
    margin-bottom: -100px;
}

.content-item h1 {
    font-family: Nunito;
    color: #fff;
}

.item-two {

    background: blueviolet;
}

.item-two .content-item-body {
    flex-direction: row-reverse;
}

.item-three {
    background: mediumvioletred;
}

.content-item .item-info {
    width: 300px;
    font-family: Nunito;
    color: #f0f0f0;
    text-align: center;
}

.item-info .info {
    margin: 22px 0px;
    display: block;
}

// .section-one {
//     display: flex;
//     justify-content: flex-start;
//     width: 80%;
// }

.content h2 {
    margin: 0px;
}

.section-main {
    position: relative;
    height: 100vh;
}

.section {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 3;
    margin-top: 25px;
}

img.download {
    width: 200px;
    margin-top: 50px;
}

.branding {
    width: 35vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .login-btn {
        border-radius: 50px;
        color: #fff;
        text-decoration: none;
        border: 1px solid #f0f0f0;
        padding: 7px 25px;
        margin-top: 30px;
        display: none;
    }

    h1,
    h3 {
        font-family: 'Nunito';
        text-align: center;
        color: #fff;
    }

    h1 {
        font-size: 19px;
        margin-bottom: 0px;
        margin-top: 10px;
        font-weight: normal;
        letter-spacing: -0.5px;
    }

    @media (max-width: 780px){
        h1 {
            font-size: 17px;
        }

        .login-btn {
            display: block;
        }
    }
}

.preview {
    position: relative;
    padding: 35px;
    margin-right: 50px;
    height: 300px;

    span {
        >img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 9px;
        }
    }
}

.main-wrapper {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    /* bottom: 0; */
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.55);
}

.logo {
    width: 80px;
}

.landing,
.landinglazy {
    width: 180px;
    border-radius: 9px;
    box-shadow: 0px 1px 20px #333;
    margin-right: 50px;
    will-change: transform;
    display: inline-block;
    transform: translateX(50px);
}

.landing1,
.landinglazy1 {
    transform: translateX(-180px);
    position: absolute;
}

.landing2,
.landinglazy2 {
    transform: translateX(-180px);
    position: absolute;
}

.anim {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.loaded {
    transform: rotate(-20deg) translateX(0px);
}

.loaded1 {
    transform: rotate(-6deg) translate(-210px, -65px);
}

.loaded2 {
    transform: rotate(10deg) translate(-174px, 22px);
}

.footer {
    display: flex;
    justify-content: center;
    padding: 0px 10px;
    box-sizing: border-box;
    background: black !important;
}

.footer-content {
    max-width: 980px;
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
    font-size: 13px;
    height: 50px;
    align-items: center;
    width: 100vw;
    flex-wrap: wrap;
}

.footer-content .copyright {
    color: #777;
    margin: 0px 6px;
}

.menu a {
    margin: 0px 6px;
    text-decoration: none;
    color: #999;
    font-size: 13px;
}

.footer-content .menu {
    display: flex;
}

@media(max-width: 900px) {
    .header {
        padding: 10px 18px;
    }

    .landing,
    .landinglazy {
        width: 125px;
        margin-right: 0px;
    }

    .branding {
        width: 100vw;
        margin-top: 30px;
    }

    .preview {
        padding: 0px;
        margin-top: 55px;
    }

    .section {
        flex-wrap: wrap-reverse;
    }

    .landing {
        transform: rotate(0deg) translateX(29px);
    }

    .landing1,
    .landinglazy1 {
        transform: translateX(-97px);
    }

    .landing2,
    .landinglazy2 {
        transform: translateX(-96px);
    }

    .loaded {
        transform: rotate(-20deg) translateX(0px);
    }

    .loaded1 {
        transform: rotate(-6deg) translate(-97px, -14px);
    }

    .loaded2 {
        transform: rotate(10deg) translate(-77px, 22px);
    }


    img.download {
        display: none;
    }
}

@media(max-width: 500px) {

    .section-main {
        height: 100vh;
    }

    .preview {
        height: 250px;
    }
}