.section-trending {
    background: #000;
    min-height: 275px;
    width: 100vw;

    &.landing {
        height: 305px;
    }

    .no-data {
        color: #fff;
        font-family: Nunito;
        display: flex;
        justify-content: center;
        padding-top: 25vh;
    }

    .tag-info {
        color: #fff;
        width: 100vw;
        padding: 2px 60px;
        box-sizing: border-box;

        @media (max-width: 900px) {
            padding: 2px 20px;
        }

        h2 {
            padding: 0px;
        }

        .subscribers {
            font-size: 12px;

            b {
                font-size: 15px;
            }
        }
    }

    h2 {
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'Nunito';
        margin: 0;
        padding: 7px 8px;

        a {
            color: #fff;
            text-decoration: none;
            border: 1px solid #f0f0f0;
            padding: 6px 20px;
            font-size: 14px;
            border-radius: 50px;
            display: flex;
            justify-content: space-around;
            align-items: center;

            img {
                width: 17px;
                margin-right: 7px;
            }
        }
    }
}

.trending-body {
    position: relative;
    overflow: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    background: #111;
    margin-top: -5px;
    width: 100%;

    .trending-content {
        display: flex;
        justify-content: flex-start;

        &.trending {
            // flex-wrap: wrap;
            //justify-content: center;
            padding: 5px 0px;

            // .trending-item {
            //     width: calc(100vw / 3.2)!important;
            //     height: calc(100vw / 3.2)!important;

            //     @media (max-width: 780px) {
            //         width: calc(100vw / 2.4)!important;
            //         height: calc(100vw / 2.3)!important;
            //     }
            // }
        }
    }

    .trending-item {
        width: 170px;
        height: 200px;
        margin: 10px;
        border-radius: 9px;
        position: relative;

        &.item-hide {
            display: none;
        }

        .playing-indicator {
            position: absolute;
            background: red;
            color: #fff;
            z-index: 99;
            font-size: 13px;
            padding: 5px 10px;
            border-radius: 50px;
            top: 5px;
            left: 5px;
        }

        .thumb {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 9px;
        }

        .trending-video-wrapper {
            position: absolute;
            background: rgba(0, 0, 0, 0.5);
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            border-radius: 9px;

            h3 {
                justify-content: center;
                color: #fff;
                display: flex;
                align-items: center;
                height: 88%;
                font-weight: 400;
                font-size: 15px;
            }

            &.share {
                cursor: pointer;
            }

            .views {
                position: absolute;
                right: 5px;
                top: 10px;
                display: flex;
                color: #fff;
                align-items: center;
                font-size: 13px;

                .view-icon {
                    width: 20px;
                    margin-right: 5px;
                }
            }

            .play-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 30px;
            }
        }
    }
}

// video {
//     width: 100%;
//     max-height: 55vh;
//     background: #000;
// }

.tv-page {
    height: 100vh;
    background: #000;
    margin-top: 100px;

    @media (max-width: 900px) {
        margin-top: 65px;
    }

    .section-trending {
        min-height: 80vh;
    }

    .trending {
        width: 100vw;

        .trending-item {
            width: 200px;
            height: 250px;
        }
    }

    .main-video-content {
        position: relative;
        min-height: 60vh;

        .video-data {
            width: 100%;
            padding: 15px 30px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #000;
            margin-bottom: 5px;
            flex-direction: column;
            flex-wrap: wrap;
            padding-bottom: 0px !important;

            @media (max-width: 780px) {
                padding: 15px 10px;
                padding-right: 1px;
            }

            .video-data-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                flex-wrap: wrap;
            }

            .video-title {
                color: #fff;
                font-weight: 400;
                font-size: 16px;
                margin-top: 0px;
                position: absolute;
                top: 26px;
                z-index: 999;
                background: rgba(0, 0, 0, 0.7);
                padding: 5px 10px;
                border-radius: 50px;
                border: 1px solid #ddd;
                left: 25px;
                max-width: 80vw;
            }

            .user-data {
                display: flex;
                align-items: center;
                text-decoration: none;

                .profile-pic {
                    width: 30px;
                    height: 30px;
                    margin-right: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        border: 1px solid #777;
                    }
                }

                .username {
                    font-size: 15px;
                    color: #fff;
                    display: flex;
                    flex-direction: column;

                    .timestamp {
                        color: #ddd;
                        font-size: 11px;
                        line-height: 15px;
                    }
                }
            }

            .stats {
                display: flex;
                padding: 9px 1px;
                align-items: center;
                flex-direction: row-reverse;

                .item {
                    margin-right: 10px;
                    color: #ddd;
                    font-size: 13px;

                    // border: 1px solid #f0f0f0;
                    // border-radius: 50px;
                    // width: 65px;
                    // height: 28px;
                    // padding: 0;
                    // display: flex;
                    // justify-content: center;
                    // align-items: center;
                    // background: transparent;


                    &.share,
                    &.views {
                        background: transparent;
                        // padding: 7px 14px;
                        border-radius: 50px;
                        border: 1px solid #f0f0f0;
                        height: 30px;
                        width: 65px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    &.views {
                        background: red;
                        border: 1px solid red;
                        min-width: 70px;
                        width: auto;
                        padding: 0px 7px;
                    }



                    h4 {
                        margin: 0;
                        display: inline-block;
                        color: #fff;
                        font-size: 13px;
                        margin-right: 3px;
                    }
                }
            }
        }
    }

    .load-more {
        background: #000;
        color: #fff;
        text-decoration: none;
        width: 100vw;
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0px;

        a {
            text-decoration: none;
            color: #fff;
            border: 1px solid #fff;
            padding: 10px 20px;
            border-radius: 64px;
        }
    }
}

%wrapper-style {
    position: fixed;
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.wrapper {
    @extend %wrapper-style;
}

.tv-page {
    .video-wrapper {
        padding-top: 60vh !important;
    }
}

.hearty-media-player {

    .progress-bar {
        z-index: 0 !important;
    }

    .video-wrapper {
        position: relative;
        width: inherit;
        height: auto;
        width: 100%;
        background: #000;

        .video-controls-wrapper {
            padding: 15px 0px !important;
        }

        l video {
            background: #000 !important;
        }
    }

    .pretty-progress-bar {
        background: #555 !important;
    }
}

.fullscreenmode {
    margin-top: 0px;

    .video-wrapper {
        padding-top: 100vh !important;
    }

    .tag-info {
        display: none;
    }
}

*::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
}

*::--webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
}

.tv-close-icon {
    color: #fff;
    text-decoration: none;
    border: 1px solid #ddd;
    width: 65px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin-top: 1px;
}